import React, { Fragment, Suspense } from "react";
import { Helmet } from "react-helmet";
import Loader from "../../components/NewView/Common/Loader";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../hooks/reducer";
import MyCourseDetailsComponent11 from "../../components/NewView/RootComponents/MyCourseDetailsComponent11";

const MyTeacherCourseDetailsComponent = React.lazy(() =>
  import("../../components/NewView/RootComponents/MyTeacherCourseDetailsComponent")
);
const MyTeacherCourseDetailsPage = () => {
  const { userCourses } = useAppSelector( (state) => state.refCoursesReducer || { userCourses: [] }); 

  const { createdCourses, status } = useAppSelector((state) => ({
      createdCourses: state.refCoursesReducer.createdCourses || [],
      status: state.refCoursesReducer.status,
    }));

  console.log(createdCourses)

  
  const { id } = useParams();
  // console.log(userCourses)
  // console.log("Course ID:", id);
  const user = useAppSelector(state => state.authReducer.data?.user) 
  console.log(user)

  
    // Знаходимо курс за id
  const course = createdCourses?.find((course) => course._id === id);

  console.log(course)
  // const course = userCourses?.find((course) => course._id === id);

  // console.log(course)

  if (!course) {
    return <p>Курс не знайдено.111</p>;
  }

  return (
    <Fragment>
      <Suspense fallback={<Loader />}>
        <Helmet>
          <title>
            YellowHub
          </title>
          <meta
            name="description"
            content="Next LMS - Professional LMS Online Education Course
            React Template"
          />
        </Helmet>
        <MyTeacherCourseDetailsComponent course={course}/>
      </Suspense>
    </Fragment>
  );
};

export default MyTeacherCourseDetailsPage;
