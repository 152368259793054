import React, { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import { useAppDispatch, useAppSelector } from "../hooks/reducer";
import { fetchUserData, updateUserData } from "../store/slices/auth";

const Settings = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.authReducer.data?.user);

  const [formData, setFormData] = useState({
    fullName: "",
    phoneNumber: "",
    emailAddress: "",
    bio: "",
  });

  const [notification, setNotification] = useState<{
    type: "success" | "error" | null;
    message: string;
  }>({ type: null, message: "" });

  useEffect(() => {
    if (user) {
      setFormData({
        fullName: user.username || "",
        phoneNumber: user.telephone || "",
        emailAddress: user.email || "",
        bio: user.bio || "",
      });
    }
  }, [user]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await dispatch(
        updateUserData({
          username: formData.fullName,
          telephone: formData.phoneNumber,
          email: formData.emailAddress,
          bio: formData.bio,
        })
      );
      setNotification({ type: "success", message: "Дані успішно оновлено!" });
    } catch (error) {
      setNotification({ type: "error", message: "Сталася помилка під час оновлення даних" });
    } finally {
      setTimeout(() => setNotification({ type: null, message: "" }), 5000); // Приховати через 5 секунд
    }
  };

  const isEmailMissing = !formData.emailAddress;

  return (
    <section>
      <div className="settingsPage py-10">
        <div className="container mx-auto">
          {notification.type && (
            <div
              className={`p-4 mb-4 text-sm rounded ${
                notification.type === "success" ? "bg-green-100 text-green-700" : "bg-red-100 text-red-700"
              }`}
            >
              {notification.message}
            </div>
          )}
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 lg:col-span-8">
              <div className="bg-white p-5 rounded-lg shadow-md">
                <Fade bottom>
                  <h2 className="text-xl font-semibold mb-5">Особиста інформація</h2>
                </Fade>
                <form onSubmit={handleSubmit}>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                    <div>
                      <label className="block text-sm font-medium mb-2" htmlFor="fullName">
                        <h2 className="text-[18px] text-[#77838F]">Ім'я</h2>
                      </label>
                      <input
                        type="text"
                        id="fullName"
                        name="fullName"
                        className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#F16126]"
                        value={formData.fullName}
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-2" htmlFor="phoneNumber">
                        <h2 className="text-[18px] text-[#77838F]">Номер телефону</h2>
                      </label>
                      <input
                        type="text"
                        id="phoneNumber"
                        name="phoneNumber"
                        className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#F16126]"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="mt-4">
                    <label className="block text-sm font-medium mb-2" htmlFor="emailAddress">
                      <h2 className="text-[18px] text-[#77838F]">Електронна адреса</h2>
                    </label>
                    <input
                      type="email"
                      id="emailAddress"
                      name="emailAddress"
                      className={`w-full border ${
                        isEmailMissing ? "border-red-500" : "border-gray-300"
                      } rounded px-3 py-2 focus:outline-none focus:ring-2 ${
                        isEmailMissing ? "focus:ring-red-500" : "focus:ring-[#F16126]"
                      }`}
                      value={formData.emailAddress}
                      onChange={handleChange}
                    />
                    {isEmailMissing && (
                      <p className="text-red-500 text-sm">Заповніть поле</p>
                    )}
                  </div>

                  <div className="mt-4">
                    <label className="block text-sm font-medium mb-2" htmlFor="bio">
                      <h2 className="text-[18px] text-[#77838F]">Декілька слів про себе</h2>
                    </label>
                    <textarea
                      id="bio"
                      name="bio"
                      className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#F16126]"
                      value={formData.bio}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="mt-6 flex justify-end gap-4">
                    <button
                      type="submit"
                      className="py-2 px-6 bg-[#F16126] text-white rounded hover:bg-opacity-90"
                    >
                      <h2 className="text-[18px] text-white">Зберегти</h2>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Settings;



// import React, { useEffect } from "react";
// import { Fade } from "react-reveal";
// import { useAppDispatch, useAppSelector } from "../hooks/reducer";
// import { fetchUserData, updateUserData } from "../store/slices/auth"; // Додайте updateUserData

// const Settings = () => {
//   const dispatch = useAppDispatch();
//   const user = useAppSelector((state) => state.authReducer.data?.user);

//   useEffect(() => {
//     // Завантаження даних користувача при відкритті сторінки
//     dispatch(fetchUserData());
//   }, [dispatch]);

//   useEffect(() => {
//     if (user) {
//       console.log("Дані користувача оновлено:", user);
//     }
//   }, [user]);

//   const handleSubmit = async (e: React.FormEvent) => {
//     e.preventDefault();

//     const formData = new FormData(e.target as HTMLFormElement);
//     const updatedData = {
//       username: formData.get("fullName") as string,
//       telephone: formData.get("phoneNumber") as string,
//       email: formData.get("emailAddress") as string,
//       bio: formData.get("bio") as string,
//     };

//     try {
//       // Відправляємо дані для оновлення користувача
//       await dispatch(updateUserData(updatedData)); // Використовуйте Redux action
//       alert("Дані успішно оновлено!");
//     } catch (error) {
//       alert("Сталася помилка під час оновлення даних");
//     }
//   };

//   const isEmailMissing = !user?.email;

//   return (
//     <section>
//       <div className="settingsPage py-10">
//         <div className="container mx-auto">
//           <div className="grid grid-cols-12 gap-4">
//             <div className="col-span-12 lg:col-span-8">
//               <div className="bg-white p-5 rounded-lg shadow-md">
//                 <Fade bottom>
//                   <h2 className="text-xl font-semibold mb-5">
//                     Особиста інформація
//                   </h2>
//                 </Fade>
//                 <form onSubmit={handleSubmit}>
//                   <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
//                     <div>
//                       <label className="block text-sm font-medium mb-2" htmlFor="fullName">
//                         <h2 className="text-[18px] text-[#77838F]">Ім'я</h2>
//                       </label>
//                       <input
//                         type="text"
//                         id="fullName"
//                         name="fullName"
//                         className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#F16126]"
//                         defaultValue={user?.username || ""}
//                       />
//                     </div>
//                     <div>
//                       <label className="block text-sm font-medium mb-2" htmlFor="phoneNumber">
//                         <h2 className="text-[18px] text-[#77838F]">Номер телефону</h2>
//                       </label>
//                       <input
//                         type="text"
//                         id="phoneNumber"
//                         name="phoneNumber"
//                         className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#F16126]"
//                         defaultValue={user?.telephone || ""}
//                       />
//                     </div>
//                   </div>

//                   <div className="mt-4">
//                     <label className="block text-sm font-medium mb-2" htmlFor="emailAddress">
//                       <h2 className="text-[18px] text-[#77838F]">Електронна адреса</h2>
//                     </label>
//                     <input
//                       type="email"
//                       id="emailAddress"
//                       name="emailAddress"
//                       className={`w-full border ${
//                         isEmailMissing ? "border-red-500" : "border-gray-300"
//                       } rounded px-3 py-2 focus:outline-none focus:ring-2 ${
//                         isEmailMissing ? "focus:ring-red-500" : "focus:ring-[#F16126]"
//                       }`}
//                       defaultValue={user?.email || ""}
//                     />
//                     {isEmailMissing && (
//                       <p className="text-red-500 text-sm">Заповніть поле</p>
//                     )}
//                   </div>

//                   <div className="mt-4">
//                     <label className="block text-sm font-medium mb-2" htmlFor="bio">
//                       <h2 className="text-[18px] text-[#77838F]">Декілька слів про себе</h2>
//                     </label>
//                     <textarea
//                       id="bio"
//                       name="bio"
//                       className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#F16126]"
//                       defaultValue={user?.bio || ""}
//                     />
//                   </div>

//                   <div className="mt-6 flex justify-end gap-4">
//                     <button
//                       type="submit"
//                       className="py-2 px-6 bg-[#F16126] text-white rounded hover:bg-opacity-90"
//                     >
//                       <h2 className="text-[18px] text-white">Зберегти</h2>
//                     </button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Settings;



// import React, { useEffect } from "react";
// import {
//   FaBookReader,
//   FaTags,
//   FaUserAlt,
//   FaStar,
// } from "react-icons/fa";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import { Fade } from "react-reveal";
// import userThree from "../images/user/user-03.png";
// import { useAppDispatch, useAppSelector } from "../hooks/reducer";
// import { fetchUserData } from "../store/slices/auth";

// const Settings = () => {
//   const dispatch = useAppDispatch();
//   const user = useAppSelector((state) => state.authReducer.data?.user);

//   // useEffect(() => {

//   //   // console.log(user)
//   //   if (!user) {
//   //     dispatch(fetchUserData()); // Завантажуємо дані користувача при завантаженні компонента
//   //   }
//   // }, [user, dispatch]);

//   useEffect(() => {
//     // Оновлення даних користувача при кожному відкриванні сторінки
//     dispatch(fetchUserData());
//   }, [dispatch]);

//   useEffect(() => {
//     // Вивід даних в консоль після оновлення
//     if (user) {
//       console.log("Дані користувача оновлено:", user);
//     }
//   }, [user]); // Слідкуємо за змінами у `user`

//   const isEmailMissing = !user?.email; // Перевірка, чи існує email у об'єкті user

//   return (
//     <section>
//       <div className="settingsPage py-10">
//         <div className="container mx-auto">
//           <div className="grid grid-cols-12 gap-4">
//             {/* Left Column */}
//             <div className="col-span-12 lg:col-span-8">
//               <div className="bg-white p-5 rounded-lg shadow-md">
//                 <Fade bottom>
//                   <h2 className="text-xl font-semibold mb-5">Особиста інформація</h2>
//                 </Fade>
//                 <form>
//                   <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
//                     <div>
//                       <label className="block text-sm font-medium mb-2" htmlFor="fullName">
//                         <h2 className="text-[18px] text-[#77838F]">Ім'я</h2>
//                       </label>
//                       <input
//                         type="text"
//                         id="fullName"
//                         className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#F16126]"
//                         defaultValue={user?.username || ""}
//                       />
//                     </div>
//                     <div>
//                       <label className="block text-sm font-medium mb-2" htmlFor="phoneNumber">
//                         <h2 className="text-[18px] text-[#77838F]">Номер телефону</h2>
//                       </label>
//                       <input
//                         type="text"
//                         id="phoneNumber"
//                         className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#F16126]"
//                         defaultValue={user?.telephone || ""}
//                       />
//                     </div>
//                   </div>

//                   <div className="mt-4">
//                     <label className="block text-sm font-medium mb-2" htmlFor="emailAddress">
//                       <h2 className="text-[18px] text-[#77838F]">Електронна адреса</h2>
//                     </label>
//                     <input
//                       type="email"
//                       id="emailAddress"
//                       className={`w-full border ${
//                         isEmailMissing ? "border-red-500" : "border-gray-300"
//                       } rounded px-3 py-2 focus:outline-none focus:ring-2 ${
//                         isEmailMissing ? "focus:ring-red-500" : "focus:ring-[#F16126]"
//                       }`}
//                       defaultValue={user?.email || ""}
//                     />
//                     {isEmailMissing && (
//                       <p className="text-red-500 text-sm ">Заповніть поле</p>
//                     )}
//                   </div>

//                   <div className="mt-4">
//                     <label className="block text-sm font-medium mb-2" htmlFor="bio">
//                       <h2 className="text-[18px] text-[#77838F]">Декілька слів про себе</h2>
//                     </label>
//                     <textarea
//                       id="bio"
//                       className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#F16126]"
//                       // defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
//                     />
//                   </div>

//                   <div className="mt-6 flex justify-end gap-4">
//                     {/* <button
//                       type="button"
//                       className="py-2 px-6 border rounded text-gray-600 hover:bg-gray-100"
//                     >
//                       <h2 className="text-[18px] text-[#77838F]">Відмінити</h2>
//                     </button> */}
//                     <button
//                       type="submit"
//                       className="py-2 px-6 bg-[#F16126] text-white rounded hover:bg-opacity-90"
//                     >
//                       <h2 className="text-[18px] text-white">Зберегти</h2>
//                     </button>
//                   </div>
//                 </form>
//               </div>
//             </div>

//             {/* <button
//                                                           type="button"
//                                                           className="flex ml-3 justify-center rounded  text-[18px] py-2 px-6 font-medium text-gray hover:bg-opacity-90"
//                                                           onClick={() => handleUpload(lesson.number)} // Викликаємо handleUpload для уроку
//                                                       ></button> */}

//             {/* Right Column */}
//             {/* <div className="col-span-12 lg:col-span-4">
//               <div className="bg-white p-5 rounded-lg shadow-md">
//                 <Fade bottom>
//                   <h3 className="text-lg font-semibold mb-4">Your Photo</h3>
//                 </Fade>
//                 <div className="flex items-center mb-4">
//                   <img src={userThree} alt="User" className="w-16 h-16 rounded-full" />
//                   <div className="ml-4">
//                     <p className="text-sm mb-2">Edit your photo</p>
//                     <div className="flex gap-3">
//                       <button className="text-blue-500 text-sm hover:underline">Delete</button>
//                       <button className="text-blue-500 text-sm hover:underline">Update</button>
//                     </div>
//                   </div>
//                 </div>
//                 <div>
//                   <label className="block text-sm font-medium mb-2" htmlFor="photoUpload">
//                     Upload Photo
//                   </label>
//                   <input
//                     type="file"
//                     id="photoUpload"
//                     className="w-full text-sm text-gray-500 border border-gray-300 rounded p-2"
//                   />
//                 </div>
//               </div>
//             </div> */}
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Settings;



