import React, { Fragment, Suspense } from "react";
import { Helmet } from "react-helmet";
// import Loader from "../Components/Common/Loader";
import Loader from "../../components/NewView/Common/Loader";
// const CourseGirdComponent = React.lazy(() =>
//   import("../Components/RootComponents/CourseGirdComponent")
// );

const CreateMyCourseComponent = React.lazy(() =>
    import("../../components/NewView/RootComponents/CreateMyCourseComponent")
  );

const MyCourseGridPage = () => {
  return (
    <Fragment>
      <Suspense fallback={<Loader />}>
        <Helmet>
          <title>
            YellowHub
          </title>
          <meta
            name="description"
            content="Next LMS - Professional LMS Online Education Course
            React Template"
          />
        </Helmet>
        <CreateMyCourseComponent />
      </Suspense>
    </Fragment>
  );
};

export default MyCourseGridPage;
