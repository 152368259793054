import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "./auth";

// Асинхронна функція для створення нового курсу
// export const createCourse = createAsyncThunk("courses/create", async (courseData: any) => {
//     try {
//         const response = await axios.post(baseUrl + "courses/create", courseData);
//         return response.data; // Повертаємо дані нового курсу
//     } catch (e) {
//         console.log("Error creating course:", e);
//         throw e;
//     }
// });

// Асинхронна функція для отримання всіх курсів
export const fetchCourses = createAsyncThunk("courses/fetchAll", async () => {
    try {
        const response = await axios.get(baseUrl + "courses");
        return response.data.courses; // Припустимо, що ваші курси знаходяться у властивості `courses`
    } catch (e) {
        console.log("Error fetching courses:", e);
        throw e;
    }
});

// Асинхронна функція для отримання курсів конкретного автора
export const fetchCoursesByAuthor = createAsyncThunk("courses/fetchByAuthor", async (authorId: string) => {
    try {
        const response = await axios.get(baseUrl + `courses?authorId=${authorId}`);
        return response.data.courses; // Припустимо, що ваші курси знаходяться у властивості `courses`
    } catch (e) {
        console.log("Error fetching courses by author:", e);
        throw e;
    }
});

// Асинхронна функція для отримання курсів користувача
export const fetchUserCourses = createAsyncThunk("userCourses/fetchUserCourses", async (userId: string) => {
    const response = await axios.get(`${baseUrl}user/${userId}/courses`);
    return response.data.courses;
});

// Асинхронна функція для завантаження домашнього завдання
export const uploadHomework = createAsyncThunk("homework/upload", async (homeworkData: FormData) => {
    try {
        const response = await axios.post(`${baseUrl}courses/upload-homework`, homeworkData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return response.data;
    } catch (e) {
        console.log("Error uploading homework:", e);
        throw e;
    }
});



export interface Lesson {
    number: number;
    title: string;
    urlRecord: string;
    urlPresentation: string;
}

export  interface ICourse {
    _id: string;
    title: string;
    thumbnail: string;
    authorId: string;
    authorName: string;
    authorImg: string;
    tag: string;
    lessons: Lesson[];
    createdAt: string;
    updatedAt: string;
}

export interface ICoursesState {
    allCourses: ICourse[] | null;
    allAuthorCourses: ICourse[] | null;
    addedCourses: ICourse[]; // Масив для доданих курсів
    userCourses: ICourse[] | null; // Поле для збереження активного курсу
    status: string; // Статус для завантаження курсів
    homeworkStatus: string; // Статус для завантаження домашніх завдань
}

const initialState: ICoursesState = {
    allCourses: null,
    allAuthorCourses: null,
    addedCourses: [],
    userCourses: null,
    status: "idle",
    homeworkStatus: "idle",
};

const coursesSlice = createSlice({
    name: "courses",
    initialState,
    reducers: {
        resetCourses: (state) => {
            state.allCourses = null;
            state.allAuthorCourses = null;
            state.addedCourses = [];
        },
        resetHomeworkStatus: (state) => {
            state.homeworkStatus = "idle";
        },
    },
    extraReducers: {
        // Завантаження курсів користувача
        [fetchUserCourses.pending.type]: (state) => {
            state.status = "loading";
            state.userCourses = null;
        },
        [fetchUserCourses.fulfilled.type]: (state, action: PayloadAction<ICourse[]>) => {
            state.status = "loaded";
            state.userCourses = action.payload;
        },
        [fetchUserCourses.rejected.type]: (state) => {
            state.status = "error";
            state.userCourses = null;
        },

        // Завантаження домашнього завдання
        [uploadHomework.pending.type]: (state) => {
            state.homeworkStatus = "loading";
        },
        [uploadHomework.fulfilled.type]: (state) => {
            state.homeworkStatus = "loaded";
        },
        [uploadHomework.rejected.type]: (state) => {
            state.homeworkStatus = "error";
        },

        // // Створення нового курсу
        // [createCourse.pending.type]: (state) => {
        //     state.status = "loading";
        // },
        // [createCourse.fulfilled.type]: (state, action: PayloadAction<ICourse>) => {
        //     state.status = "loaded";
        //     state.addedCourses.push(action.payload);
        // },
        // [createCourse.rejected.type]: (state) => {
        //     state.status = "error";
        // },
    },
});

export default coursesSlice.reducer;
export const { resetCourses, resetHomeworkStatus } = coursesSlice.actions;



