import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IAuthData, User } from "../../types/user";
import axios from "axios";

// Базовий URL вашого API
// export const baseUrl = 'https://78ae-176-98-23-183.ngrok-free.app/';
// export const baseUrl = process.env.REACT_APP_API_URL || 'https://yellow-hub-35b14f48a8c1.herokuapp.com/';
// export const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4444/';
// export const baseUrl = process.env.REACT_APP_API_URL || 'http://185.237.206.239:4444/';
// export const baseUrl = process.env.REACT_APP_API_URL || 'https://185.237.206.239:8443/';
export const baseUrl = 'https://yellow-hub-35b14f48a8c1.herokuapp.com/';


// Асинхронна функція для авторизації
export const fetchAuth = createAsyncThunk('auth/login', async (params: any) => {
    try {
        const response = await axios.post<IAuthData>(baseUrl + `auth/login`, params);

        // Збережіть токен у localStorage
        localStorage.setItem('token', response.data.token);
        // localStorage.setItem('userId', response.data.user._id);

        // Встановіть токен у заголовки для майбутніх запитів
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
        // console.log(response.data);
        return response.data;
    } catch (e) {
        console.log(e);
    }
});

// Асинхронна функція для реєстрації
export const fetchRegister = createAsyncThunk('auth/fetchRegister', async (params: any) => {
    try {
        const response = await axios.post<IAuthData>(baseUrl + `auth/register`, params);

        // Збережіть токен у localStorage
        localStorage.setItem('token', response.data.token);
        // localStorage.setItem('userId', response.data.user._id);

        // Встановіть токен у заголовки для майбутніх запитів
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;

        return response.data;
    } catch (e) {
        console.log(e);
    }
});


// Асинхронна функція для отримання даних користувача
// export const fetchUserData = createAsyncThunk('auth/fetchUserData', async () => {
//     try {
//         const token = localStorage.getItem('token');

//         const id = localStorage.getItem('userId');

//         if (!token) {
//             throw new Error('Token not found');
//         }

//         // Додаємо токен у заголовки
//         const response = await axios.get(baseUrl + 'auth/getUser', {
//             headers: {
//                 Authorization: `Bearer ${id}`,
//             },
//         });

//         return response.data;
//     } catch (e) {
//         console.error('Error fetching user data:', e);
//     }
// });

export const fetchUserData = createAsyncThunk('auth/fetchUserData', async () => {
    try {
        const token = localStorage.getItem('token');

        if (!token) {
            throw new Error('Token not found');
        }

        // Відправка токена у форматі JSON через POST
        const response = await axios.post(baseUrl + 'auth/getUser', { token }, { 
            headers: {
                'Content-Type': 'application/json' // Вказуємо, що відправляємо JSON
            },
        });

        // console.log('Sending POST request to:', baseUrl + 'auth/getUser');
        // console.log('Token being used:', token);
        // console.log(response.data)

        return response.data;
    } catch (e) {
        console.error('Error fetching user data:', e);
    }
});

export const updateUserData = createAsyncThunk(
    "auth/updateUserData",
    async (updatedData: Record<string, any>, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem("token");
            if (!token) throw new Error("Token not found");

            // console.log("Token:", token);
            // console.log("Updated Data:", updatedData);

            const response = await axios.put(
                `${baseUrl}auth/updateUser`,
                updatedData,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            // console.log("Response Data:", response.data);
            return response.data.user;
        } catch (error: any) {
            console.error("Error Response:", error.response);
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);




// Функція виходу
export const logout = () => {
    localStorage.removeItem('token');
    delete axios.defaults.headers.common['Authorization'];
};

interface IUserState {
    data: null | IAuthData;
    status: string;
    isAuthenticated: boolean; // Додаємо поле для перевірки авторизації
}

const initialState: IUserState = {
    data: null,
    status: 'loading',
    isAuthenticated: false // Початково користувач не авторизований
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetData: (state) => {
            state.data = null;
            state.isAuthenticated = false;
        },
        setAuthenticated: (state) => {
            state.isAuthenticated = true; // Встановлюємо авторизацію в true
        },
        logout: (state) => {
            // Скидаємо дані стану при логауті
            state.data = null;
            state.isAuthenticated = false;
        },
    },
    extraReducers: {
        [fetchAuth.pending.type]: (state) => {
            state.status = 'loading';
            state.data = null;
        },
        [fetchAuth.fulfilled.type]: (state, action: PayloadAction<IAuthData>) => {
            state.status = 'loaded';
            state.data = action.payload;
            state.isAuthenticated = true; // Встановлюємо авторизацію в true
        },
        [fetchAuth.rejected.type]: (state) => {
            state.status = 'error';
            state.data = null;
            state.isAuthenticated = false;
        },
        [fetchRegister.pending.type]: (state) => {
            state.status = 'loading';
            state.data = null;
        },
        [fetchRegister.fulfilled.type]: (state, action: PayloadAction<IAuthData>) => {
            state.status = 'loaded';
            state.data = action.payload;
            state.isAuthenticated = true; // Встановлюємо авторизацію в true
        },
        [fetchRegister.rejected.type]: (state) => {
            state.status = 'error';
            state.data = null;
            state.isAuthenticated = false;
        },
        [fetchUserData.pending.type]: (state) => {
            state.status = 'loading';
        },
        [fetchUserData.fulfilled.type]: (state, action: PayloadAction<IAuthData>) => {
            state.status = 'loaded';
            // console.log(action.payload)
            state.data = action.payload;
            state.isAuthenticated = true; // Встановлюємо авторизацію в true
        },
        [fetchUserData.rejected.type]: (state) => {
            state.status = 'error';
            state.data = null;
            state.isAuthenticated = false;
        },
        [updateUserData.pending.type]: (state) => {
            state.status = 'loading'; // Починаємо оновлення
        },
        [updateUserData.fulfilled.type]: (state, action: PayloadAction<Partial<User>>) => {
            state.status = 'loaded';
            if (state.data) {
                state.data.user = { ...state.data.user, ...action.payload };
            }
        },
        [updateUserData.rejected.type]: (state) => {
            state.status = 'error'; // Помилка під час оновлення
        },
    }
});

export default authSlice.reducer;
export const { resetData, setAuthenticated } = authSlice.actions;



// server {
//     listen 8080;
//     server_name 185.237.206.239;

//     return 301 https://$host$request_uri;
// }

// server {
//     listen 8443 ssl;
//     server_name 185.237.206.239;

//     ssl_certificate /etc/letsencrypt/live/api.yellowhub.com.ua/fullchain.pem;
//     ssl_certificate_key /etc/letsencrypt/live/api.yellowhub.com.ua/privkey.pem;

//     ssl_protocols TLSv1.2 TLSv1.3;
//     ssl_ciphers 'ECDHE-RSA-AES128-GCM-SHA256:ECDHE-RSA-AES256-GCM-SHA384:ECDHE-RSA-AES128-SHA256:ECDHE-RSA-AES256-SHA384';

//     location / {
//         proxy_pass http://185.237.206.239:4444;
//         proxy_http_version 1.1;
//         proxy_set_header Upgrade $http_upgrade;
//         proxy_set_header Connection 'upgrade';
//         proxy_set_header Host $host;
//         proxy_cache_bypass $http_upgrade;
//     }
// }

// server {
//     listen 8080;
//     server_name 185.237.206.239;

//     return 301 https://$host$request_uri;
// }

// server {
//     listen 8443 ssl;
//     server_name 185.237.206.239;

//     ssl_certificate /etc/letsencrypt/live/api.yellowhub.com.ua/fullchain.pem;
//     ssl_certificate_key /etc/letsencrypt/live/api.yellowhub.com.ua/privkey.pem;

//     ssl_protocols TLSv1.2 TLSv1.3;
//     ssl_ciphers 'ECDHE-RSA-AES128-GCM-SHA256:ECDHE-RSA-AES256-GCM-SHA384:ECDHE-RSA-AES128-SHA256:ECDHE-RSA-AES256-SHA384';

//     location / {
//         proxy_pass http://185.237.206.239:4444;
//         proxy_http_version 1.1;
//         proxy_set_header Upgrade $http_upgrade;
//         proxy_set_header Connection 'upgrade';
//         proxy_set_header Host $host;
//         proxy_cache_bypass $http_upgrade;
//     }
// }


// server {
//     listen 80;
//     server_name api.yellowhub.com.ua;

//     location /.well-known/acme-challenge/ {
//         root /var/www/html;
//     }

//     location / {
//         return 404;
//     }
// }


// server {
//     listen 80;
//     server_name api.yellowhub.com.ua;

//     return 301 https://$host$request_uri;
// }


// server {
//     listen 443 ssl;
//     server_name api.yellowhub.com.ua;

//     ssl_certificate /etc/letsencrypt/live/api.yellowhub.com.ua/fullchain.pem;
//     ssl_certificate_key /etc/letsencrypt/live/api.yellowhub.com.ua/privkey.pem;

//     location / {
//         proxy_pass http://185.237.206.239:4444;
//         proxy_http_version 1.1;
//         proxy_set_header Upgrade $http_upgrade;
//         proxy_set_header Connection 'upgrade';
//         proxy_set_header Host $host;
//         proxy_cache_bypass $http_upgrade;
//     }
// }