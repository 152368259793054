import React, { Fragment, Suspense } from "react";
import { Helmet } from "react-helmet";

import Loader from "../../components/NewView/Common/Loader";
// const HomeComponent = React.lazy(() =>
//   import("../Components/RootComponents/HomeComponent")
// );

const HomeComponent = React.lazy(() =>
    import("../../components/NewView/RootComponents/HomeComponent")
  );
const HomePage = () => {
  return (
    <Fragment>
      <Suspense fallback={<Loader />}>
        <Helmet>
          <title>
            YellowHub
          </title>
          <meta
            name="description"
            content="Next LMS - Professional LMS Online Education Course React Template"
          />
        </Helmet>
        <HomeComponent />
      </Suspense>
    </Fragment>
  );
};

export default HomePage;
