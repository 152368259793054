import React, { useState } from 'react';

import { Route, Routes } from 'react-router-dom';








const TestPage = () => {


  const [assignments, setAssignments] = useState([
    { id: 1, student: "Іван Петренко", homework: "Тема 1: Алгебра", grade: "", graded: false },
    { id: 2, student: "Марія Коваль", homework: "Тема 2: Геометрія", grade: "", graded: false },
  ]);

  const [courses, setCourses] = useState<string[]>([]);
  const [newCourse, setNewCourse] = useState("");

  // Обробка введення оцінки
  const handleGradeSubmit = (id: number) => {
    setAssignments((prev) =>
      prev.map((item) =>
        item.id === id && item.grade.trim() !== ""
          ? { ...item, graded: true }
          : item
      )
    );
  };

  // Обробка додавання нового курсу
  const handleAddCourse = () => {
    if (newCourse.trim()) {
      setCourses((prev) => [...prev, newCourse]);
      setNewCourse("");
    }
  };

  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      {/* Шапка */}
      <header className="bg-white shadow-md py-4 px-8 flex justify-between items-center mb-6">
        <div className="flex items-center gap-4">
          <div className="w-10 h-10 bg-gray-300 rounded-full flex items-center justify-center">
            {/* Іконка профілю */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6 text-gray-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5.121 17.804A4 4 0 017 16h10a4 4 0 011.879 1.804M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </div>
          <span className="text-gray-700 font-semibold text-lg">Профіль викладача</span>
        </div>
        <button
          onClick={() => alert("Ви вийшли з кабінету!")}
          className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded"
        >
          Вийти з кабінету
        </button>
      </header>
      {/* Виставлення оцінок */}
      <div className="bg-white p-6 rounded-lg shadow-lg mb-8">
        <h2 className="text-2xl font-semibold mb-4">Виставлення оцінок</h2>
        <table className="w-full table-auto">
          <thead>
            <tr className="bg-gray-200">
              <th className="px-4 py-2">#</th>
              <th className="px-4 py-2">Студент</th>
              <th className="px-4 py-2">Домашнє завдання</th>
              <th className="px-4 py-2">Оцінка</th>
              <th className="px-4 py-2">Дія</th>
            </tr>
          </thead>
          <tbody>
            {assignments.map((assignment) => (
              <tr key={assignment.id} className="border-b">
                <td className="px-4 py-2">{assignment.id}</td>
                <td className="px-4 py-2">{assignment.student}</td>
                <td className="px-4 py-2">{assignment.homework}</td>
                <td className="px-4 py-2">
                  <input
                    type="text"
                    value={assignment.grade}
                    disabled={assignment.graded}
                    onChange={(e) =>
                      setAssignments((prev) =>
                        prev.map((item) =>
                          item.id === assignment.id
                            ? { ...item, grade: e.target.value }
                            : item
                        )
                      )
                    }
                    placeholder="Введіть оцінку"
                    className="border rounded px-2 py-1 w-full"
                  />
                </td>
                <td className="px-4 py-2">
                  {assignment.graded ? (
                    <span className="text-green-500 text-xl">✅</span>
                  ) : (
                    <button
                      onClick={() => handleGradeSubmit(assignment.id)}
                      className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
                    >
                      Оцінити
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Завантаження курсів */}
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold mb-4">Завантаження курсів</h2>
        <div className="flex gap-4 mb-4">
          <input
            type="text"
            value={newCourse}
            onChange={(e) => setNewCourse(e.target.value)}
            placeholder="Назва курсу"
            className="border rounded px-4 py-2 flex-1"
          />
          <button
            onClick={handleAddCourse}
            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
          >
            Завантажити
          </button>
        </div>
        <ul>
          {courses.map((course, index) => (
            <li
              key={index}
              className="p-2 bg-gray-100 rounded mb-2 border text-gray-700 shadow-sm"
            >
              {course}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );

};
export default TestPage;