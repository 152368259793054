import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "./auth";

// Інтерфейси даних курсу
export interface Lecture {
    title: string;
    videoUrl: string;
    liveStreamUrl: string;
    attachedFile: string; // URL до файлу
}

export interface Section {
    title: string;
    lectures: Lecture[];
}

export interface Module {
    title: string;
    sections: Section[];
}

export interface Timeframes {
    start: string; // ISO-формат дати
    end: string;   // ISO-формат дати
}

export interface Author {
    id: string; // ID автора
    name: string;
    img: string | null; // URL до зображення, якщо є
}

export interface ICourse {
    _id: string;
    title: string;
    author: Author;
    thumbnail: string; // URL до мініатюри
    tag: string[]; // Теги курсу
    timeframes: Timeframes;
    content: Module[]; // Модулі з секціями та лекціями
    createdAt: string; // Дата створення
    updatedAt: string; // Дата оновлення
}

export interface ICoursesStateRef {
    allCourses: ICourse[] | null; // Усі курси
    createdCourses: ICourse[] | null; // Створені курси
    addedCourses: ICourse[]; // Додані курси
    userCourses: ICourse[] | null; // Курси користувача
    status: string; // Статус для створення курсу
}

const initialState: ICoursesStateRef = {
    allCourses: null,
    createdCourses: [],
    addedCourses: [],
    userCourses: null, // Додано нове поле
    status: "idle",
};

// Асинхронна функція для створення нового курсу
export const createCourse = createAsyncThunk("courses/create", async (courseData: FormData) => {
    try {
        const response = await axios.post(`${baseUrl}newcourses/newcteate-course`, courseData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return response.data.course; // Повертаємо об'єкт створеного курсу
    } catch (e) {
        console.error("Error creating course:", e);
        throw e;
    }
});

// Асинхронна функція для отримання створених курсів
export const fetchCreatedCourses = createAsyncThunk("courses/fetchCreatedCourses", async (token: string) => {
    try {
        const response = await axios.get(`${baseUrl}users/created-courses`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        console.log(response.data)
        return response.data.createdCourses; // Масив створених курсів
    } catch (e) {
        console.error("Error fetching created courses:", e);
        throw e;
    }
});

export const fetchUserCourses = createAsyncThunk("courses/fetchUserCourses", async (userId: string) => {
    try {
        const response = await axios.get(`${baseUrl}user/${userId}/courses`);
        
        return response.data.courses; // Повертає масив курсів користувача
    } catch (e) {
        console.error("Error fetching user courses:", e);
        throw e;
    }
});


// Асинхронна функція для оновлення курсу
export const updateCourse = createAsyncThunk("courses/update", async (courseData: FormData) => {
    try {
        const response = await axios.put(`${baseUrl}courses/update`, courseData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        console.log("Оновлений курс:", response.data); // Виводимо відповідь у консоль
        return response.data; // Повертаємо оновлений курс
    } catch (e) {
        console.error("Error updating course:", e);
        throw e;
    }
});

// Слайс для роботи з курсами
const coursesSlice = createSlice({
    name: "courses",
    initialState,
    reducers: {
        resetCourses: (state) => {
            state.allCourses = null;
            state.createdCourses = null;
            state.addedCourses = [];
        },
    },
    extraReducers: (builder) => {
        builder
            // Завантаження створених курсів
            .addCase(fetchCreatedCourses.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchCreatedCourses.fulfilled, (state, action: PayloadAction<ICourse[]>) => {
                state.status = "loaded";
                state.createdCourses = action.payload;
            })
            .addCase(fetchCreatedCourses.rejected, (state) => {
                state.status = "error";
            })
            // Створення нового курсу
            .addCase(createCourse.pending, (state) => {
                state.status = "loading";
            })
            .addCase(createCourse.fulfilled, (state, action: PayloadAction<ICourse>) => {
                state.status = "loaded";
                state.addedCourses.push(action.payload);
            })
            .addCase(createCourse.rejected, (state) => {
                state.status = "error";
            })
            // Завантаження курсів користувача
            .addCase(fetchUserCourses.pending, (state) => {
                state.status = "loading";
                state.userCourses = null; // Очищення перед новим завантаженням
            })
            .addCase(fetchUserCourses.fulfilled, (state, action: PayloadAction<ICourse[]>) => {
                state.status = "loaded";
                state.userCourses = action.payload;
            })
            .addCase(fetchUserCourses.rejected, (state) => {
                state.status = "error";
                state.userCourses = null; // У разі помилки
            })
            // Оновлення курсу
            .addCase(updateCourse.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateCourse.fulfilled, (state, action: PayloadAction<{ course: ICourse; authorCourses: ICourse[] }>) => {
                state.status = "loaded";
                // console.log("Курс успішно оновлено:", action.payload); // Виводимо курс у консоль

                console.log("action.payload:", action.payload);

                // Оновлюємо поле createdCourses усіма курсами автора
                state.createdCourses = action.payload.authorCourses;

                console.log("Курс успішно оновлено:", action.payload.authorCourses)
            })
            .addCase(updateCourse.rejected, (state) => {
                state.status = "error";
                console.error("Помилка під час оновлення курсу");
            });
    },
});

export default coursesSlice.reducer;
export const { resetCourses } = coursesSlice.actions;





// import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
// import { baseUrl } from "./auth";

// // Асинхронна функція для створення нового курсу
// export const createCourse = createAsyncThunk("courses/create", async (courseData: FormData) => {
//     try {
//         const response = await axios.post(`${baseUrl}newcourses/newcteate-course`, courseData, {
//             headers: {
//                 "Content-Type": "multipart/form-data",
//             },
//         });
//         return response.data; // Повертаємо дані нового курсу
//     } catch (e) {
//         console.log("Error creating course:", e);
//         throw e;
//     }
// });

// // Асинхронна функція для отримання всіх курсів
// export const fetchCourses = createAsyncThunk("courses/fetchAll", async () => {
//     try {
//         const response = await axios.get(`${baseUrl}courses`);
//         return response.data.courses; // Припустимо, що ваші курси знаходяться у властивості `courses`
//     } catch (e) {
//         console.log("Error fetching courses:", e);
//         throw e;
//     }
// });

// // Асинхронна функція для отримання курсів конкретного автора
// export const fetchCoursesByAuthor = createAsyncThunk("courses/fetchByAuthor", async (authorId: string) => {
//     try {
//         const response = await axios.get(`${baseUrl}courses?authorId=${authorId}`);
//         return response.data.courses; // Припустимо, що ваші курси знаходяться у властивості `courses`
//     } catch (e) {
//         console.log("Error fetching courses by author:", e);
//         throw e;
//     }
// });

// // Інтерфейси даних курсу
// export interface Lesson {
//     number: number;
//     title: string;
//     urlRecord: string;
//     urlPresentation: string;
// }

// export interface ICourse {
//     _id: string;
//     title: string;
//     thumbnail: string;
//     authorId: string;
//     authorName: string;
//     authorImg: string;
//     tag: string[];
//     lessons: Lesson[];
//     createdAt: string;
//     updatedAt: string;
// }

// export interface ICoursesState {
//     allCourses: ICourse[] | null;
//     addedCourses: ICourse[]; // Масив для доданих курсів
//     status: string; // Статус для створення курсу
// }

// const initialState: ICoursesState = {
//     allCourses: null,
//     addedCourses: [],
//     status: "idle",
// };

// // Слайс для роботи з курсами
// const coursesSlice = createSlice({
//     name: "courses",
//     initialState,
//     reducers: {
//         resetCourses: (state) => {
//             state.allCourses = null;
//             state.addedCourses = [];
//         },
//     },
//     extraReducers: (builder) => {
//         builder
//             // Завантаження всіх курсів
//             .addCase(fetchCourses.pending, (state) => {
//                 state.status = "loading";
//             })
//             .addCase(fetchCourses.fulfilled, (state, action: PayloadAction<ICourse[]>) => {
//                 state.status = "loaded";
//                 state.allCourses = action.payload;
//             })
//             .addCase(fetchCourses.rejected, (state) => {
//                 state.status = "error";
//             })
//             // Створення нового курсу
//             .addCase(createCourse.pending, (state) => {
//                 state.status = "loading";
//             })
//             .addCase(createCourse.fulfilled, (state, action: PayloadAction<ICourse>) => {
//                 state.status = "loaded";
//                 state.addedCourses.push(action.payload);
//             })
//             .addCase(createCourse.rejected, (state) => {
//                 state.status = "error";
//             });
//     },
// });

// export default coursesSlice.reducer;
// export const { resetCourses } = coursesSlice.actions;
