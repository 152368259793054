import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from './reducers/UserSlice'
import authReducer from './slices/auth'
import coursesReducer from './slices/courses'
import refCoursesReducer from './slices/ref_courses'

// export const baseUrl = 'https://8058-176-98-23-183.ngrok-free.app/';

const rootReducer = combineReducers({
    authReducer,
    userReducer,
    coursesReducer,
    refCoursesReducer

})

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
    })
}

// export const baseUrl = 'https://8058-176-98-23-183.ngrok-free.app/';
export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']