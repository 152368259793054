import React, { Fragment, Suspense } from "react";
import { Helmet } from "react-helmet";
import Loader from "../../components/NewView/Common/Loader";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../hooks/reducer";
import MyCourseDetailsComponent11 from "../../components/NewView/RootComponents/MyCourseDetailsComponent11";

const MyCourseDetailsComponent = React.lazy(() =>
  import("../../components/NewView/RootComponents/MyCourseDetailsComponent")
);
const CourseDetailsPage = () => {
  const { userCourses } = useAppSelector( (state) => state.refCoursesReducer || { userCourses: [] }); 
  const { id } = useParams();
  // console.log(userCourses)
  // console.log("Course ID:", id);

  

  const course = userCourses?.find((course) => course._id === id);

  // console.log(course)

  if (!course) {
    return <p>Курс не знайдено.</p>;
  }

  return (
    <Fragment>
      <Suspense fallback={<Loader />}>
        <Helmet>
          <title>
            YellowHub
          </title>
          <meta
            name="description"
            content="Next LMS - Professional LMS Online Education Course
            React Template"
          />
        </Helmet>
        <MyCourseDetailsComponent course={course}/>
      </Suspense>
    </Fragment>
  );
};

export default CourseDetailsPage;
